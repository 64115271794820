import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import SEO from '../components/seo'
import Layout from '../components/layout'

import InternalLinkButton from '../components/atoms/InternalLinkButton'
import CtaBanner from '../components/molecules/CtaBanner'
import FlcGallery from '../components/organisms/FlcGallery'
import PageHeaderWithImage from '../components/organisms/PageHeaderWithImage'

const useStyles = makeStyles((theme) => ({
    links: {
        marginTop: theme.spacing(6),
    },
}))

function ServicePage({ data }) {
    const classes = useStyles()

    const {
        headline,
        bannerBlurb,
        bannerImage,
        imageGallery,
        ctaBanner,
        seoTitle,
        seoDescription,
    } = data.contentfulService

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <PageHeaderWithImage
                image={bannerImage}
                headline={headline.childMarkdownRemark.rawMarkdownBody}
                blurb={bannerBlurb.childMarkdownRemark.html}
            >
                <div className={classes.links}>
                    <InternalLinkButton
                        link={'/contact/'}
                        text={'Get in touch'}
                    />
                </div>
            </PageHeaderWithImage>
            {imageGallery && <FlcGallery images={imageGallery.images} />}
            {ctaBanner && (
                <CtaBanner
                    title={ctaBanner.bannerTitle}
                    description={ctaBanner.bannerText}
                >
                    {ctaBanner.bannerLinks &&
                        ctaBanner.bannerLinks.map((link) => {
                            return (
                                <InternalLinkButton
                                    key={link.link}
                                    link={link.link}
                                    text={link.label}
                                    variant={link.variant}
                                />
                            )
                        })}
                </CtaBanner>
            )}
        </Layout>
    )
}

ServicePage.propTypes = {
    data: PropTypes.object,
}

export default ServicePage

export const query = graphql`
    query servicePageQuery($slug: String!) {
        contentfulService(slug: { eq: $slug }) {
            id
            slug
            title
            headline {
                childMarkdownRemark {
                    html
                    rawMarkdownBody
                }
            }
            bannerBlurb {
                childMarkdownRemark {
                    html
                }
            }
            bannerImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            mainContent {
                childMarkdownRemark {
                    html
                }
            }
            imageGallery {
                id
                images {
                    id
                    description
                    fluid {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                    file {
                        url
                        fileName
                        details {
                            size
                            image {
                                width
                                height
                            }
                        }
                    }
                }
            }
            ctaBanner {
                bannerText
                bannerTitle
                bannerLinks {
                    label
                    link
                    variant
                }
            }
            seoTitle
            seoDescription
        }
    }
`
