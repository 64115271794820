import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import {
    OVERLAY_OPACITY,
    TEXT_SHADOW,
    MAX_CONTENT_WIDTH_SML,
} from '../../constants/global.js'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        textShadow: TEXT_SHADOW,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'hidden',
        '&:before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.primary.main,
            opacity: OVERLAY_OPACITY,
            zIndex: 1,
        },
    },
    bannerImage: {
        position: 'absolute!important',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 0,
    },
    bannerTitle: {
        textTransform: 'uppercase',
        fontSize: theme.typography.pxToRem(38),
        fontWeight: theme.typography.fontWeightLight,
        marginTop: 0,
        marginBottom: theme.spacing(4),

        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(52),
        },

        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(68),
        },
    },
    bannerContent: {
        maxWidth: MAX_CONTENT_WIDTH_SML,
        padding: `${theme.spacing(20)}px ${theme.spacing(3)}px`,
        zIndex: 2,
    },
}))

function PageHeaderWithImage({ headline, blurb, image, children }) {
    const classes = useStyles()

    return (
        <header className={classes.root}>
            <Img fluid={image.fluid} className={classes.bannerImage} />
            <div className={classes.bannerContent}>
                <Typography
                    className={classes.bannerTitle}
                    variant="h1"
                    dangerouslySetInnerHTML={{ __html: headline }}
                />
                <Typography
                    variant="body1"
                    component={'div'}
                    dangerouslySetInnerHTML={{ __html: blurb }}
                />
                {children}
            </div>
        </header>
    )
}

PageHeaderWithImage.propTypes = {
    headline: PropTypes.string.isRequired,
    blurb: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    children: PropTypes.node,
}

export default PageHeaderWithImage
