import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import FlcButton from './FlcButton'

import { trackEvent } from '../../utils/analytics'

function InternalLinkButton({ className, text, link, gaEventLabel, ...other }) {
  const handleOnClick = () => {
    if (window) {
      const gaEventTrackingLabel =
        gaEventLabel || `Page: ${window.location.pathname} - Text: ${text}`

      trackEvent('InternalLinkButton', 'Click', gaEventTrackingLabel, link)
    }

    navigate(link)
  }

  return (
    <FlcButton
      className={className}
      text={text}
      onClick={handleOnClick}
      {...other}
    />
  )
}

InternalLinkButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  gaEventLabel: PropTypes.string,
}

export default InternalLinkButton
