import React from 'react'
import { makeStyles } from '@material-ui/styles'

import ZoomInIcon from '@material-ui/icons/ZoomIn'

import {
    TRANSITION_DURATION,
    HOVER_OPACITY,
    TRANSITION_EASE,
} from '../../constants/global'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                '& > svg': {
                    opacity: HOVER_OPACITY,
                },
            },
        },
    },
    icon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: `opacity ${TRANSITION_DURATION} ${TRANSITION_EASE}`,
        fontSize: '64px',
        color: theme.palette.text.primary,
        opacity: 0,
        pointerEvents: 'none',
    },
}))

function FlcGalleryImage({ index, onClick, photo, margin }) {
    const classes = useStyles()

    const handleOnKeyDown = (e, { index, photo }) => {
        // If enter key is pressed
        if (e.keyCode === 13) {
            onClick(e, { index, photo })
        }
    }

    return (
        <div
            role="button"
            tabIndex={index}
            onClick={(e) => onClick(e, { index })}
            onKeyDown={(e) => handleOnKeyDown(e, { index, photo })}
            className={classes.root}
            style={{ margin, height: photo.height, width: photo.width }}
        >
            <img {...photo} alt={photo.alt} />
            <ZoomInIcon className={classes.icon} />
        </div>
    )
}

export default FlcGalleryImage
