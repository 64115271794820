import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import Gallery from 'react-photo-gallery'
import { makeStyles } from '@material-ui/styles'
import { CSSTransition } from 'react-transition-group'

import FlcGalleryImage from '../atoms/FlcGalleryImage'
import FlcCarousel from '../organisms/FlcCarousel'

import { trackEvent } from '../../utils/analytics'

import '../../styles/flc-gallery-transitions.css'

const useStyles = makeStyles((theme) => ({
    gallery: {
        padding: '1px',
        backgroundColor: theme.palette.primary.light,
    },
}))

function FlcGallery({ images }) {
    const classes = useStyles()

    const [width, setWidth] = useState(-1)
    const [currentImage, setCurrentImage] = useState(0)
    const [sliderIsOpen, setSliderIsOpen] = useState(false)

    const galleryImages = images.map((image) => {
        return {
            key: image.id,
            src: image.file.url,
            srcSet: image.fluid.srcSet,
            width: image.file.details.image.width,
            height: image.file.details.image.height,
            alt: image.description,
        }
    })

    const imageRenderer = useCallback(
        ({ index, key, onClick, photo, margin }) => (
            <FlcGalleryImage
                index={index}
                key={key}
                onClick={onClick}
                photo={photo}
                margin={margin}
            />
        ),
        []
    )

    function closeSlider() {
        setCurrentImage(0)
        setSliderIsOpen(false)
    }

    function openSlider(event, obj) {
        if (event) {
            event.preventDefault()
        }

        setCurrentImage(obj.index)
        setSliderIsOpen(true)

        if (window) {
            trackEvent(
                'Gallery',
                'Open Slider',
                `Page: ${window.location.pathname}`
            )
        }
    }

    return (
        <Measure
            bounds
            onResize={(contentRect) => setWidth(contentRect.bounds.width)}
        >
            {({ measureRef }) => {
                if (width < 1) {
                    return <div ref={measureRef} />
                }

                return (
                    <div className={classes.gallery} ref={measureRef}>
                        <Gallery
                            margin={1}
                            targetRowHeight={400}
                            photos={galleryImages}
                            renderImage={imageRenderer}
                            onClick={openSlider}
                        />
                        <CSSTransition
                            in={sliderIsOpen}
                            timeout={300}
                            classNames="flc-gallery"
                            unmountOnExit
                        >
                            <FlcCarousel
                                images={images}
                                currentImage={currentImage}
                                onCloseSlider={closeSlider}
                            />
                        </CSSTransition>
                    </div>
                )
            }}
        </Measure>
    )
}

FlcGallery.propTypes = {
    images: PropTypes.array,
}

export default FlcGallery
