import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ScrollLock from 'react-scrolllock'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import PageBackgroundImage from '../atoms/PageBackgroundImage'

import BluePrintImage2 from '../../assets/images/blueprint2-optimised.jpg'

import '../../styles/slick.min.css'

import {
    HOVER_OPACITY,
    TRANSITION_ALL,
    ZI_GALLERY,
} from '../../constants/global'

const useStyles = makeStyles((theme) => ({
    overlaySliderContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.dark,
        zIndex: ZI_GALLERY,
    },
    overlaySlider: {
        '&.slick-slider, .slick-list, .slick-track': {
            height: '100%',
        },
        '&.slick-initialized .slick-slide, .slick-slide': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '.slick-arrow': {
            position: 'absolute',
            top: '50%',
        },
    },
    overlaySliderImage: {
        display: 'block!important',
        width: 'auto!important',
        height: 'auto!important',
        cursor: 'ew-resize',
        margin: 0,
        maxWidth: '90vw',
        maxHeight: '90vh',
        border: `solid 2px ${theme.palette.primary.light}`,
        outline: 'none',
        [theme.breakpoints.up('md')]: {
            maxWidth: '85vw',
            maxHeight: '85vh',
        },
    },
    sliderControl: {
        position: 'absolute',
        cursor: 'pointer',
        color: theme.palette.text.primary,
        transition: TRANSITION_ALL,
        zIndex: ZI_GALLERY + 1,
        [theme.breakpoints.up('lg')]: {
            '&:hover': {
                opacity: HOVER_OPACITY,
            },
        },
    },
    closeIcon: {
        top: theme.spacing(1),
        right: theme.spacing(1),
        fontSize: 24,
        [theme.breakpoints.up('md')]: {
            top: theme.spacing(2),
            right: theme.spacing(2),
            fontSize: 34,
        },
    },
    prevArrow: {
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        fontSize: 30,
        [theme.breakpoints.up('md')]: {
            top: '50%',
            left: theme.spacing(2),
            fontSize: 40,
        },
        '&.slick-disabled': {
            opacity: 0.3,
            cursor: 'default',
            pointerEvents: 'none',
        },
    },
    nextArrow: {
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        fontSize: 30,
        [theme.breakpoints.up('md')]: {
            top: '50%',
            right: theme.spacing(2),
            fontSize: 40,
        },
        '&.slick-disabled': {
            opacity: 0.3,
            cursor: 'default',
            pointerEvents: 'none',
        },
    },
}))

function NextArrow({ className, customClassName, onClick }) {
    return (
        <ChevronRightIcon
            className={classnames(className, customClassName)}
            onClick={onClick}
        />
    )
}

function PrevArrow({ className, customClassName, onClick }) {
    return (
        <ChevronLeftIcon
            className={classnames(className, customClassName)}
            onClick={onClick}
        />
    )
}

function FlcCarousel({ images, currentImage, onCloseSlider }) {
    const classes = useStyles()
    const sliderContainerEl = useRef(null)

    useEffect(() => {
        // Focus on the current slide on init so that keyboard navigation works
        const currentSlide =
            sliderContainerEl &&
            sliderContainerEl.current.getElementsByClassName('slick-current')[0]

        currentSlide && currentSlide.focus()
    }, [])

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        initialSlide: currentImage,
        focusOnSelect: true,
        nextArrow: (
            <NextArrow
                customClassName={classnames(
                    classes.sliderControl,
                    classes.nextArrow
                )}
            />
        ),
        prevArrow: (
            <PrevArrow
                customClassName={classnames(
                    classes.sliderControl,
                    classes.prevArrow
                )}
            />
        ),
    }

    const sliderImages = images.map((image) => (
        <img
            key={image.id}
            className={classes.overlaySliderImage}
            src={image.file.url}
            alt={image.description}
        />
    ))

    return (
        <div ref={sliderContainerEl} className={classes.overlaySliderContainer}>
            <ScrollLock />
            <PageBackgroundImage image={BluePrintImage2} />
            <CloseIcon
                onClick={onCloseSlider}
                className={classnames(classes.sliderControl, classes.closeIcon)}
            />
            <Slider className={classes.overlaySlider} {...sliderSettings}>
                {sliderImages}
            </Slider>
        </div>
    )
}

FlcCarousel.propTypes = {
    images: PropTypes.array,
    currentImage: PropTypes.number,
    onCloseSlider: PropTypes.func,
}

export default FlcCarousel
