import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import ContentSection, {
    contentSectionWidths,
    contentSectionSpacing,
} from '../atoms/ContentSection'
import ContentArticle from '../atoms/ContentArticle'

import { MAX_CONTENT_WIDTH_SML } from '../../constants/global.js'

import theme from '../../styles/theme'

const useStyles = makeStyles((theme) => ({
    ctaBanner: {
        '& article': {
            margin: '0 auto',
            maxWidth: MAX_CONTENT_WIDTH_SML,
            textAlign: 'center',
        },

        '& button': {
            marginTop: `${theme.spacing(5)}px`,
        },
    },
    title: {
        marginTop: 0,
    },
}))

function CtaBanner({ title, description, children, ...other }) {
    const classes = useStyles()

    return (
        <ContentSection
            className={classes.ctaBanner}
            width={contentSectionWidths.full}
            spacing={contentSectionSpacing.large}
            backgroundColor={theme.palette.primary.dark}
            {...other}
        >
            <ContentArticle>
                <Typography className={classes.title} variant="h1">
                    {title}
                </Typography>
                <Typography variant="h4" component="p">
                    {description}
                </Typography>
                {children && children}
            </ContentArticle>
        </ContentSection>
    )
}

CtaBanner.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    chidren: PropTypes.node,
}

export default CtaBanner
