import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        margin: `${theme.spacing(10)}px auto`,
        '&:last-child': {
            marginBottom: 0,
        },
    },
}))

function ContentArticle({ children, className, ...other }) {
    const classes = useStyles()

    return (
        <article
            className={classNames(classes.container, className)}
            {...other}
        >
            {children}
        </article>
    )
}

ContentArticle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default ContentArticle
